html {
  background: rgb(59, 134, 209);
  background: linear-gradient(
    90deg,
    rgba(59, 134, 209, 1) 0%,
    rgba(239, 100, 147, 1) 100%
  );
}

a {
  color: #fff;
}

body {
  background: rgb(59, 134, 209);
  background: linear-gradient(
    90deg,
    rgba(59, 134, 209, 1) 0%,
    rgba(239, 100, 147, 1) 100%
  );
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

#overlay {
  position: relative;
  width: 75px;
  margin: 5px -85px;
  visibility: hidden;
  opacity: 0.1;
}

.site {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.podium {
  background: rgb(59, 134, 209);
  background: linear-gradient(
    180deg,
    rgba(59, 134, 209, 1) 0%,
    rgba(239, 100, 147, 1) 100%
  );
  width: 85%;
  height: 85%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35), 0 23px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  margin: 5vh auto;
}

.open {
  max-height: 100%;
  max-width: 75%;
  align-self: flex-end;
}

.about {
  align-self: flex-end;
}

.about p {
  margin-top: 6px;
  margin-bottom: 3em;
  padding-right: 5px;
}

.about h3 {
  margin: 0;
}

.boxbutton {
  margin: 50px;
  border-radius: 2px;
  color: #fff;
  text-align: center;
  line-height: 50px;
  box-shadow: 20px 5px 30px #cf77f3, 0px 5px 30px #009bff,
    -20px 5px 30px #2ac9db;
  background-image: linear-gradient(
    -90deg,
    #cf77f3 0%,
    #009bff 47%,
    #2ac9db 100%
  );
  border: 1px solid #fff;
  min-width: 100px;
  cursor: pointer;
}

.mega {
  font-family: "Goldman", cursive;
  font-size: 4em;
  font-style: italic;
}

.full {
  width: 100%;
  height: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: -6px 0;
  padding: 0 15px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 50px;
}

.camContainer {
  position: relative;
  flex: 1;
  padding: 10px;
}

video {
  max-height: 100%;
  box-shadow: 0 23px 20px 0 rgba(0, 0, 0, 0.19);
}

#result,
#detection {
  position: absolute;
  left: 10px;
}

.sideButton {
  width: 45%;
  margin: 10px auto 10px auto;
  cursor: pointer;
  padding-right: 10%;
}

#sidePanel {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.dim {
  opacity: 0.5;
  cursor: default;
}

.rotateButton {
  transform: rotate(90deg);
  padding-top: 10%;
  margin-bottom: 0;
}

.sLabel {
  text-align: center;
  padding-right: 10%;
  margin-top: 0;
}

.buttonRow {
  display: flex;
}

.miniButton {
  width: 38px;
}

.modal {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(166, 77, 255, 0.7);
  transition: all 0.4s;
  z-index: 3;
  /* experimenting */
  width: 100vw;
  height: 100vh;
  left: -8vw;
  top: -5vh;
}
.modal:target {
  visibility: visible;
  opacity: 1;
}
.modal__content {
  border-radius: 4px;
  position: relative;
  background: #57a0ff;
  padding: 1em 2em;
  text-align: center;
  z-index: 5;
  border: 1px solid;
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fffd;
  text-decoration: none;
}
